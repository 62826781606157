* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100vh;
  background: rgb(24, 24, 24);
}

body {
  overflow-x: hidden;
  font-family: sans-serif;
}

.webgl {
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  mix-blend-mode: exclusion;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  place-items: center;
}

h1 {
  font-size: 8rem;
  text-transform: uppercase;
  color: white;
}

h3 {
  font-size: 2rem;
  text-transform: uppercase;
  color: white;
}

h4 {
  font-size: 2rem;
  text-transform: uppercase;
  color: white;
  line-height: 3rem;
}

a {
  text-decoration: none;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 25vh;
}